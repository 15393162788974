<!--
 * @Description:
 * @Author:
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-11 09:53:02
-->
<template>
  <el-table highlight-current-row :data="reconciliationList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="tradName" label="贸易企业名称" ></el-table-column>
    <el-table-column prop="reconciliationName" label="对账单标题" >
      <template slot-scope="scope">
        {{scope.row.reconciliationName}}({{scope.row.startDate.substring(0,10)}}~{{scope.row.endDate.substring(0,10)}})
      </template>
    </el-table-column>
    <el-table-column prop="countCar" label="对账车数(车)" ></el-table-column>
    <el-table-column prop="shipmentWeight" label="合计装车量(吨)" ></el-table-column>
    <el-table-column prop="totalWeight" label="合计结算量(吨)" ></el-table-column>
    <el-table-column prop="totalAmount" label="合计结算金额(元)" ></el-table-column>
    <el-table-column v-if="radio==2" prop="totalAmount" label="合计对账金额（元）" ></el-table-column>
    <el-table-column label="操作" width="200px" fixed="right">
      <template slot-scope="scope">
        <el-button type="text" @click="lookDetails(scope.row,scope.row.reconciliationId)">查看详情</el-button>
      </template>
    </el-table-column>
  </el-table>

</template>

<script>

  export default {
    name: "ReconciliationTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      },
      radio: {
        type: String,
        default() {
          return ''
        }
      }
    },
    components: {

    },
    methods: {
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('reconciliation-list');
      },
      lookDetails(scope,id){
        this.$router.replace({path: '/pages/biz/gas_factory/finance/reconciliation/tradeDetails/look',query: {scope: scope,id: id}})
      },
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
