<template>
    <div style="height: 100%">
        <!-- 卡片视图 -->
        <el-card style="height: 100%;overflow:auto;">
            <!-- 顶部面包屑导航 -->
            <breadcrumb-nav>
                <template v-slot:firstMenu>财务中心</template>
                <template v-slot:secondMenu>对账管理</template>
            </breadcrumb-nav>
            <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
                对账管理
            </div>
            <div class="radioBox">
                <el-radio-group v-model="radio1" v-if="type==2" @change="agreeChange">
                    <el-radio-button label="1">未核对</el-radio-button>
                    <el-radio-button label="2">已核对</el-radio-button>
                </el-radio-group>
            </div>
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="贸易企业名称" v-if='type==2'>
                    <el-select v-model="formInline.region" placeholder="请选择" @change="onSubmit" clearable>
                        <el-option
                                v-for="item in enterpriseList"
                                :key="item.tardId"
                                :label="item.tardName"
                                :value="item.tardId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="购液企业名称" v-if='type==1'>
                    <el-select v-model="formInline.region" placeholder="请选择" @change="onSubmit" clearable>
                        <el-option
                                v-for="item in tradList"
                                :key="item.stationId"
                                :label = "item.stationName"
                                :value="item.stationId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="buttonBox">
                <el-button type="primary" @click="orderPage">
                    {{ type == 1 ? '已对账列表' : type == 2 ? '未对账列表' : '' }}
                </el-button>
            </div>
            <!-- table表格区域 -->
            <trade-reconciliation-table v-if="type==1" :reconciliation-list="goodsList"
                                        @role-list='updateRoleList'/>
            <reconciliation-table v-if="type==2" :reconciliation-list="goodsList"
                                  @role-list='updateRoleList' :radio="radio1"/>
            <div style="float: right;">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.pageNo"
                        :page-sizes="[10, 20, 30, 50]"
                        :page-size="queryInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>


    </div>
</template>

<script>
import BreadcrumbNav from "../../common/BreadcrumbNav";
import ReconciliationTable from './ReconciliationComponents/ReconciliationTable';
import {getFinancialListRequest, getReconciliationListRequest,emptionListRequest} from "network/financial";
import TradeReconciliationTable from './ReconciliationComponents/TradeReconciliationTable.vue';
import {getEnterpriseRequest} from 'network/plan'


export default {
    name: "List",
    components: {
        BreadcrumbNav,
        ReconciliationTable,
        TradeReconciliationTable
    },
    data() {
        return {
            type: localStorage.getItem('type'),
            radio1: '1',
            queryInfo: {
                pageNo: 1,
                pageSize: 10
            },
            formInline: {
                user: '',
                region: ''
            },
            goodsList: [],
            tradList: [],
            total: 0,
            enterpriseList: []
        }
    },
    created() {
        this.getGoodsList();
        this.getEnterpriseList();
        this.getTardList()
    },
    methods: {
        onSubmit(){
            this.getGoodsList();
        },
        getEnterpriseList() {
            getEnterpriseRequest({
                stationId: localStorage.getItem('enterpriseId')
            }).then(res => {
                let result = res.data
                if (result.code !== 200) {

                }
                this.enterpriseList = result.data
            })
        },
        orderPage() {
            if (this.type == 1) {
                this.$router.replace('/pages/biz/gas_factory/finance/reconciliation/TradeOrder')
            }
            if (this.type == 2) {
                this.$router.replace('/pages/biz/gas_factory/finance/reconciliation/order')
            }
        },
        getGoodsList() {
            let newType = localStorage.getItem('type')
            if (newType == 1) {
              getReconciliationListRequest({
                    page: this.queryInfo,
                  stationId:this.formInline.region
                }).then(res => {
                    let result = res.data;
                    if (result.code !== 200) {
                        return this.alertMessage(result.msg, 'error');
                    }
                    this.goodsList = result.data.list;
                    this.total = result.data.total;
                });
            }
            if (newType == 2) {
                getFinancialListRequest({
                    page: this.queryInfo,
                    reconciliationStatus: this.radio1,
                    tardId:this.formInline.region
                }).then(res => {
                    console.log(res);
                    let result = res.data;
                    if (result.code !== 200) {
                        return this.alertMessage(result.msg, 'error');
                    }
                    this.goodsList = result.data.list;
                    this.total = result.data.total;
                });
            }
        },
        getTardList() {
            emptionListRequest({
                tardId: localStorage.getItem('enterpriseId')
            }).then(res => {
                let result = res.data
                if (result.code !== 200) {

                }
                this.tradList = result.data
            })
        },
        agreeChange(val) {
            console.log(val);
            this.queryInfo.pageNo = 1
            this.queryInfo.pageSize = 10
            this.getGoodsList()

        },

        // 每页显示的数据条数发送变化
        handleSizeChange(newSize) {
            this.queryInfo.pageNo = 1
            this.queryInfo.pageSize = newSize;
            this.getGoodsList();
        },

        // 当前页码发生变化
        handleCurrentChange(newPage) {
            this.queryInfo.pageNo = newPage;
            this.getGoodsList();
        },
        // 监听子组件中发出的事件，重新获取角色列表
        updateRoleList() {
            // this.getRolesList();
        }
    }
}
</script>

<style scoped>

.radioBox {
    margin-top: 30px;
    margin-bottom: 15px;
}

.buttonBox {
    display: flex;
}

.el-button {
    background-color: var(--theme_bg_color);
    border: 1px solid var(--theme_bg_color)
}
</style>

