<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-24 15:32:05
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-11 09:53:36
-->
<template>
  <el-table highlight-current-row :data="reconciliationList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="stationName" label="购液企业名称" ></el-table-column>
    <el-table-column prop="notReconciledCount" label="未对账车数(车)" ></el-table-column>
    <el-table-column label="操作" width="200px" fixed="right">
      <template slot-scope="scope">
        <trade-reconciliation-modal :station-id='scope.row.stationId' :name='scope.row.stationName' :ballance="scope.row.balance" :minDate="scope.row.minDate" :maxDate="scope.row.maxDate"/>
      </template>
    </el-table-column>
  </el-table>
  
</template>

<script>
import TradeReconciliationModal from './TradeReconciliationModal.vue';

  export default {
    name: "ReconciliationTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        TradeReconciliationModal
      
    },
    methods: {
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('reconciliation-list');
      }
    }
  }
</script>

<style scoped>


</style>
