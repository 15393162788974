<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text"  @click="openModal(ballance)">发起对账</el-button>
      </el-col>
    </el-row>
  <el-dialog
            title="创建对账单"
            :visible.sync="setRightDialogVisible"
            :append-to-body='true'
            :modal-append-to-body='true'
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="对账计划日期" prop="time">
          <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 400px" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="对账单标题" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入内容" style="width: 400px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
export default {
  name: "TradeReconciliationModal",
  props: {
    stationId: {
      type: Number,
      default() {
        return {}
      }
    },
    name:{
      type: String,
      default() {
        return {}
      }
    },
    ballance: {
      type: Number,
    },
    minDate: {
      type: String
    },
    maxDate: {
      type: String
    }
  },
  data() {
    return {
      property: 'value',
      setRightDialogVisible: false,
      ruleForm: {
          time: '',
          title: '',
          stationId: this.stationId,
          name: this.name,
          ballance: this.ballance,
        },
      rules: {
        
        time: [
            { required: true, message: '请选择日期', trigger: 'change' }
          ],
        title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
      },
      

    };
  },
  created() {
    
  },
  methods: {
    openModal(id){
      this.setRightDialogVisible = true
      this.ruleForm.time = [this.minDate,this.maxDate]
      // let time = timestampToTime(new Date())
      this.ruleForm.title = this.timestampToTime()+'对账单'
    },

    timestampToTime(){
      let date = new Date();
      let Y = date.getFullYear() + '';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '';
      return Y + M + D ;
    },
    
    submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$router.replace({path: '/pages/biz/gas_factory/finance/reconciliation/tradeDetails',query: {scope: this.ruleForm}})
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.setRightDialogVisible=false
        this.$refs[formName].resetFields();
      }
  }
}
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>